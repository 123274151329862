import _ from "lodash";
import "./style.css";
import "normalize.css";
import printMe from "./print.js";
import Logo from "./logo-red.jpg";

function component() {
  const element = document.createElement("div");

  const header = document.getElementsByTagName("header")[0];
  const logo = new Image();
  logo.src = Logo;
  header.insertAdjacentElement("afterbegin", logo);

  // Lodash, now imported by this script
  return element;
}

document.body.appendChild(component());
